header {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
  /* border: 1px solid red; */
}
.header_container {
  text-align: center;
  height: 100%;
  position: relative;

  /* border: 1px solid red; */
}

/* *********** CTA ******* */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* *********** HEADER SOCIALS ******* */

.header_socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header_socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* *********** IMAGE ******* */

.me {
  background: linear-gradient(var(--color-primary), transparent);

  width: 21rem;
  height: 24rem;
  /* border: 1px solid red; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
}

/* ******* SCROLL DOWN ******* */

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 8rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* *********** MEDIA QUERIES (MEDIUM DEVICES) ********** */

@media screen and (max-width: 1024px) {
  .header {
    height: 68vh;
  }
}

@media screen and (max-width: 600px) {
  .header {
    height: 100vh;
  }

  .header_socials,
  .scroll_down {
    display: none;
  }
}
